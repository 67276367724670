import React from 'react'
import {Link} from 'react-router-dom'

function App() {
  return (
    <div className="pt-5 bg-purple">
      <div className="container text-center " >
        <Link to='/'><div className="logofooter"></div></Link>       
        <div className=" m-0 p-0 pb-5 text-center">
          <Link to='/' className='text-purple  small'> © 2023 </Link>
        </div>
      </div>      
    </div>
  )
}

export default App