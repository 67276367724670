import React from 'react'
import alejandro from '../img/team/alejandro.jpg'
import luis from '../img/team/luis.jpeg'
import shaymer from '../img/team/shaymer.jpg'
import carlos from '../img/team/carlos.jpg'


function App() {  
  return (
    <section id='team' className=' bg-purple'>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 pt-5" >
              <h3 className="h2 text-light p-3 p-lg-5 text-start">Equipo principal</h3>

              <div className="row px-lg-5 mx-4 mx-lg-5">

                <div className="col-12 col-lg-6 pb-3 pb-lg-5">
                  <h1 className="h3 text-start text-yellow">Desarrollo y tecnología</h1>
                  <div className="m-lg-3">
                    <div className="row">
                      <div className="col-12 col-lg-3">
                        <img loading="lazy" src={alejandro} alt='...' className='img-team'/>
                      </div>
                      <div className="col-12 col-lg-9 py-3">                 
                        <h6 className="text-light fw-bold">Alejandro Jojoa Moreno</h6>
                        <p className="text-purple h4">Desarrollador web</p>
                      </div>
                    </div>
                  </div>       
                  <div className="m-lg-3">
                    <div className="row">
                      <div className="col-12 col-lg-3">
                        <img loading="lazy" src={luis} alt='...' className='img-team'/>
                      </div>
                      <div className="col-12 col-lg-9 py-3">                 
                        <h6 className="text-light fw-bold">Luis Delgado</h6>
                        <p className="text-purple h4">Programador</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-6 pb-3 pb-lg-5">
                  <h1 className="h3 text-start text-yellow">Arte y diseño</h1>
                  <div className="m-lg-3">
                    <div className="row">
                      <div className="col-12 col-lg-3">
                        <img loading="lazy" src={shaymer} alt='...' className='img-team'/>
                      </div>
                      <div className="col-12 col-lg-9 py-3">                 
                        <h6 className="text-light fw-bold">Shaymer Hernandez</h6>
                        <p className="text-purple h4">Artista visual</p>
                      </div>
                    </div>
                  </div>       
                </div>
                <div className="col-12 col-lg-6 pb-3 pb-lg-5">
                  <h1 className="h3 text-start text-yellow">Administración y ventas</h1>
                  <div className="m-lg-3">
                    <div className="row">
                      <div className="col-3">
                        <img loading="lazy" src={carlos} alt='...' className='img-team'/>
                      </div>
                      <div className="col-12 col-lg-9 py-3">                 
                        <h6 className="text-light fw-bold">Carlos Bastidas</h6>
                        <p className="text-purple h4">Relaciones comerciales</p>
                      </div>
                    </div>
                  </div>       
                </div>
              </div>      
            </div>           
          </div>   
        </div>  
    </section>
  )
}

export default App