import React from 'react'
import './redes.scss'
import { BsFacebook, BsWhatsapp, BsInstagram, BsTwitter } from 'react-icons/bs'

function App() {
  return (
    <div className="redes">  
      <a href="https://www.facebook.com/uappexofficial" target="_blank" rel="noreferrer" className="red" title="Facebook"><BsFacebook/></a>
      <a href="https://twitter.com/UappexC" className="red" target="_blank" rel="noreferrer" title="Twitter"><BsTwitter/></a>
      <a href="https://www.instagram.com/uappex_official/" className="red" rel="noreferrer" target="_blank" title="Instagram"><BsInstagram/></a>
      <a href="https://wa.me/message/GYL33WASIT3VP1" className="red" title="Whatsapp" rel="noreferrer" target="_blank"><BsWhatsapp/></a>       
    </div>
  )
}

export default App