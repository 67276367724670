import React from 'react'
import { AiOutlineMenu } from 'react-icons/ai'
import { GrFormClose } from 'react-icons/gr'

let start = 'animate__fadeInRight'
let end = 'animate__fadeOutLeft'

function on() {
  let menuplus = document.getElementsByClassName('menuplus-dropdown')[0]
  menuplus.style.display = 'inline-block'
  menuplus.classList.remove('animate__animated', end, 'animate__faster')
  menuplus.classList.add('animate__animated', start, 'animate__faster')
}

function off() {
  let menuplus = document.getElementsByClassName('menuplus-dropdown')[0]
  menuplus.classList.remove('animate__animated', start, 'animate__faster')
  menuplus.classList.add('animate__animated', end, 'animate__faster')
  setTimeout(function() {
    menuplus.style.display = 'none'
  }, 300)
}

function Nav() {

  return (

    <div className='fixed-top'>        
      <div className="menuplus">
        <div  className="menuplus-button" onClick={() => on()}><AiOutlineMenu className='text-white'/></div>

        <div className="menuplus-dropdown container-fluid bg-purple rounded p-lg-5 p-4 m-3 shadow">
          <div className='close' onClick={() => off()}><GrFormClose/></div>                 
            <div className="row pt-3">
              <div className="col-12 col-lg-6 p-3">
                <h1 className='text-purple h3'>Proyectos actuales</h1>  
                <div className='h4'>
                  <a className='text-yellow d-block' href="https://cubanitosmifavorito.com/" target="_blank" rel="noreferrer">Cubanitos</a>
                  <a className='text-yellow d-block' href="https://obii.online" target="_blank" rel="noreferrer">Obii</a>
                  <a className='text-yellow d-block' href="https://desarrollourbanisticonombradiasas.com/" target="_blank" rel="noreferrer">Nombradia</a>
                  <a className='text-yellow d-block' href="https://escueladebailelucyarroyo.com/" target="_blank" rel="noreferrer">Escuela de baile</a>
                  <a className='text-yellow d-block' href="https://mipresidentenft.com/" target="_blank" rel="noreferrer">Mi presidente NFT</a>
                </div>
              </div>    
                
              <div className="col-12 col-lg-6 p-3">
                <h1 className='text-purple h3'>Contacto</h1>  
                <div className='h4'>
                  <a className='text-yellow d-block' href='https://api.whatsapp.com/send?phone=573175520004' target="_blank" rel="noreferrer">+57 317 552 0004</a>
                  <a className='text-yellow d-block' href="mailto:uappex@gmail.co" target="_blank" rel="noreferrer">uappex@gmail.com</a>
                </div>
              </div>  

              <div className="col-12 col-lg-6 p-3">
                <a href="#team"><h1 className='text-purple h3'>Equipo</h1>  </a>
              </div>     
              <div className="col-12 col-lg-6 p-3">
                <a href="#services"><h1 className='text-purple h3'>Servicios</h1>  </a>
              </div>         
            </div>         
        </div>    

      </div>
    </div>
  )
}

export default Nav