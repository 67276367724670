import React from 'react'
import WOW from 'wow.js'
import AOS from 'aos';
import 'aos/dist/aos.css'
import Nav from '../components/Nav'
import Header from '../components/Header'
import Services from '../components/Services'
import Team from '../components/Team'
import Footer from '../components/Footer'
import Redes from '../components/Redes/Redes'


class Main extends React.Component {
    
    componentDidMount() {
      new WOW().init()
      AOS.init()
    }   

  
    render() {

      return (
        <>
        <div className="bg"></div>
        <Nav/>           
        <Header/>
        <Services/>
        <Team/>
        <Footer/>  
        <Redes/>
        </>
      )
    }
  }

export default Main