import React, { useState} from 'react'
import { GrFormPrevious } from 'react-icons/gr'
import { BsInstagram } from 'react-icons/bs'
import s1 from './../img/services/s1.png'
import s2 from './../img/services/s2.png'
import s3 from './../img/services/s3.png'
import s4 from './../img/services/s4.png'
import s5 from './../img/services/s5.png'
import s6 from './../img/services/s6.png'
import s7 from './../img/services/s7.png'
import s8 from './../img/services/s8.png'
import s9 from './../img/services/s9.png'
import s10 from './../img/services/s10.png'
import s11 from './../img/services/s11.png'
import s12 from './../img/services/s12.png'
import s13 from './../img/services/s13.png'

function App() {  

  const [title, setTitle] = useState(<></>)
  const [left, setLeft] = useState(<></>)
  const [right, setRight] = useState(<></>)

  
  let start = 'animate__fadeInRight'
  let end = 'animate__fadeOutRight'

  function on(title, left, right) {
    setTitle(title)
    setLeft(left)
    setRight(right)
    let space = document.getElementsByClassName('space')[0]
    space.style.display = 'inline-block'
    space.classList.remove('animate__animated', end, 'animate__faster')
    space.classList.add('animate__animated', start, 'animate__faster')
  }

  function off() {
    let space = document.getElementsByClassName('space')[0]
    space.classList.remove('animate__animated', start, 'animate__faster')
    space.classList.add('animate__animated', end, 'animate__faster')
    setTimeout(function() {
      space.style.display = 'none'
    }, 300)
  }

  return (
    <>
    <div className="space bg-purple-dark m-0">
      <div onClick={() => off()} className='previous'><GrFormPrevious/></div>      
      <div className="container-fluid px-lg-5 px-0 ">
        <div className="row px-lg-5 px-lg-3 m-0">
          <div className="col-12 col-lg-6">
            <div className="center-y"> 
              <div className="d-block px-4">
                <h3 className="h2 text-purple text-center text-lg-start pt-5 mt-3 mt-lg-0 pt-lg-0 ">{title}</h3>
                <div className="h4 text-light">
                  {left}
                </div>
              </div>       
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="center-y"> 
              <div className="d-block px-5">
                {right}
              </div>
            </div>           
          </div>
        </div>
      </div>
    </div>

    <section id='services'>
        <div className="container-fluid bg-purple" >
          <div className="row pt-5">
            <div className="col-12 px-lg-5">
              <h3 className="h2 text-yellow px-1 ps-3 py-lg-5 py-2" >Desarrollo informático</h3>
              <div className='h4 text-purple'>
                <div className="row px-lg-5 px-3 mx-lg-5 mx-3" >
                  <div className="col-12 col-lg-6 pb-lg-3 pb-2">
                    <h1 
                      className="h3 text-start" 
                      onClick={() => on(
                        <>Landing page</>,
                        <>
                          <br/>
                          Hosting
                          <br/>
                          SSL's
                          <br/>
                          Dominio
                          <br/>
                          Diseño de página
                          <br/>
                          Logo <small className="text-purple">(opcional)</small>
                          <br/>
                          Enlaces a redes sociales
                          <br/>
                          PQR's <small className="text-purple">(opcional)</small>
                          <br/>
                          Google maps <small className="text-purple">(opcional)</small>
                          <br/>
                          Google analytics
                          <br/>
                          <><h1 className="text-light h2 d-block pt-4">500.000 COP*</h1></> 
                        </>,
                        <img className='img-service' src={s1} loading="lazy" title='grafic1' alt= ''/>,
                      
                      )}>
                        Landing page
                    </h1>
                  </div>
                  <div className="col-12 col-lg-6 pb-lg-3 pb-2">
                    <h1 
                      className="h3 text-start" 
                      onClick={() => on(
                        <>Tienda online</>,
                        <>  
                          <br/>
                           Hosting
                          <br/>
                          SSL's
                          <br/>
                          Dominio
                          <br/>
                          Usuario administrador
                          <br/>
                          Diseño de página
                          <br/>                          
                          Lista de productos <small className="text-purple">(Editable)</small>
                          <br/>
                          Pasarela de pago <small className="text-purple">(Mercado pago)</small>
                          <br/>
                          Logo <small className="text-purple">(opcional)</small>
                          <br/>
                          Enlaces a redes sociales
                          <br/>
                          PQR's
                          <br/>
                          Google maps <small className="text-purple">(opcional)</small>
                          <br/>
                          Google analytics
                          <br/>
                          <><h1 className="text-light h2 d-block pt-4">2.500.000 COP*</h1></> 
                        </>,
                        <img className='img-service' src={s2} loading="lazy" title='grafic1' alt= ''/>,
                      )}>
                        Tienda online
                    </h1>
                  </div>
                  <div className="col-12 col-lg-6 pb-lg-3 pb-2">
                    <h1 
                      className="h3 text-start" 
                      onClick={() => on(
                        <>App's web</>,
                        <>
                          <br/>
                          <h1 className='h3'>Para grandes ideas, grandes soluciones.</h1>
                          <br/>
                          <div className="text-center">
                            Contáctanos <a href="https://www.instagram.com/uappex_official/" className="red" rel="noreferrer" target="_blank" title="Instagram"><BsInstagram/></a>
                          </div>
                        </>,
                        <img className='img-service' src={s3} loading="lazy" title='grafic1' alt= ''/>,

                      )}>
                        App's web
                    </h1>
                  </div>
                  <div className="col-12 col-lg-6 pb-lg-3 pb-2">
                    <h1 
                      className="h3 text-start" 
                      onClick={() => on(
                        <>Soporte tecnológico</>,
                        <>
                          <br/>
                          <h1 className='h3'>Si deseas soporte técnico o asesoramiento con algun producto tecnológico, te podemos ayudar.</h1>                           
                          <br/>
                          <br/>
                          <div className="text-center">
                            Contáctanos <a href="https://www.instagram.com/uappex_official/" className="red" rel="noreferrer" target="_blank" title="Instagram"><BsInstagram/></a>
                          </div>
                        </>,
                        <img className='img-service' src={s4} loading="lazy" title='grafic1' alt= ''/>,
                      )}>
                        Soporte tecnológico
                    </h1>
                  </div>    
                </div>                             
              </div>
            </div>           
          </div>   
          <div className="row">
            <div className="col-12 px-lg-5 px-3 " >
              <h3 className="h2 text-yellow px-1 ps-3 py-lg-5 py-2">Diseño y arte visual</h3>
              <div className='h4 text-purple'>
                <div className="row px-lg-5 px-3 mx-lg-5 mx-3">
                  <div className="col-12 col-lg-6 pb-lg-3 pb-2">
                    <h1 
                      className="h3 text-start" 
                      onClick={() => on(
                        <>Imagen corporativa</>,
                        <>
                          <br/>
                          <h1 className="h3">                            
                            Toda gran idea parte de un buen Diseño de marca.
                            <br/>
                            <br/>
                            Diseño de logo, namign, paleta de colores, tipografía y más.
                          </h1>
                        </>,
                        <img className='img-service' src={s5} loading="lazy" title='grafic1' alt= ''/>,                      
                      )}>
                        Imagen corporativa
                    </h1>
                  </div>
                  <div className="col-12 col-lg-6 pb-lg-3 pb-2">
                    <h1 
                      className="h3 text-start" 
                      onClick={() => on(
                        <>Diseño y experiencia de usuario (UX/UI)</>,
                        <>
                          <br/>
                          <h1 className="h3">Se centra en la experiencia general del usuario final, incluidas sus percepciones, emociones y respuestas al producto.  </h1>                        
                        </>,
                        <img className='img-service' src={s6} loading="lazy" title='grafic1' alt= ''/>, 
                      )}>
                        Diseño y experiencia de usuario (UX/UI)
                    </h1>
                  </div>
                  <div className="col-12 col-lg-6 pb-lg-3 pb-2">
                    <h1 
                      className="h3 text-start" 
                      onClick={() => on(
                        <>Ilustración digital</>,
                        <>
                          <br/>
                          <h1 className="h3">Creación de contenido visual con temática y estilo de representación. <br/><br/>Como temática cartoons y estilos minimalista, realista o fantástico.</h1>
                        </>,
                        <img className='img-service' src={s7} loading="lazy" title='grafic1' alt= ''/>                      
                      )}>
                      Ilustración digital
                    </h1>
                  </div>
                  <div className="col-12 col-lg-6 pb-lg-3 pb-2">
                    <h1 
                      className="h3 text-start" 
                      onClick={() => on(
                        <>Modelado 3D</>,
                        <>
                          <br/>
                          <h1 className="h3">
                            Escultura digital y modelado industrial o arquítectónico con parámetros requeridos por la industria para usos en ilustración, animación e impresión.
                          </h1>
                        </>,
                        <img className='img-service' src={s8} loading="lazy" title='grafic1' alt= ''/>,                       
                      )}>
                      Modelado 3D
                    </h1>
                  </div>
                  <div className="col-12 col-lg-6 pb-lg-3 pb-2">
                    <h1 
                      className="h3 text-start" 
                      onClick={() => on(
                        <>Creación de escenarios y personajes</>,
                        <>
                          <br/> 
                          <h1 className="h3">Para animación, vídeojuegos, historietas o simplemente una cara amigable para tu marca, un personaje memorable te acerca a tus usuarios.</h1>
                        </>,
                        <img className='img-service' src={s9} loading="lazy" title='grafic1' alt= ''/>,                       
                      )}>
                      Creación de escenarios y personajes
                    </h1>
                  </div>
                </div>                             
              </div>
            </div>           
          </div>   

          <div className="row">
            <div className="col-12 px-lg-5 px-3 " >
              <h3 className="h2 text-yellow px-1 ps-3 py-lg-5 py-2">Desarrollo blockchain</h3>
              <div className='h4 text-purple'>
                <div className="row px-lg-5 px-3 mx-lg-5 mx-3">
                  <div className="col-12 col-lg-6 pb-lg-3 pb-2">
                    <h1 
                      className="h3 text-start" 
                      onClick={() => on(
                        <> Soporte y consultoría Blockchain</>,
                        <>
                          <br/>
                          <h1 className="h3">Blockchain, una gran base de datos distribuida que la hace inviolable, especialmente para transacciones.</h1>
                          <br/>
                          <br/>
                          <div className="text-center">
                            Contáctanos <a href="https://www.instagram.com/uappex_official/" className="red" rel="noreferrer" target="_blank" title="Instagram"><BsInstagram/></a>
                          </div>
                        </>,
                        <img className='img-service' src={s10} loading="lazy" title='grafic1' alt= ''/>,                      
                      )}>
                      Soporte y consultoría Blockchain
                    </h1>
                  </div>
                  <div className="col-12 col-lg-6 pb-lg-3 pb-2">
                    <h1 
                      className="h3 text-start" 
                      onClick={() => on(
                        <>Smart contracts</>,
                        <>
                          <br/>
                          <h1 className="h3">Son contratos digitales, ejecutados por un conjunto específico de reglas definidas por código informático, que es replicado y ejecutado por la red Blockchain.</h1>
                          <br/>
                          <br/>
                          <div className="text-center">
                            Contáctanos <a href="https://www.instagram.com/uappex_official/" className="red" rel="noreferrer" target="_blank" title="Instagram"><BsInstagram/></a>
                          </div>
                        </>,
                        <img className='img-service' src={s11} loading="lazy" title='grafic1' alt= ''/>,                       
                      )}>
                      Smart contracts
                    </h1>
                  </div>
                  <div className="col-12 col-lg-6 pb-lg-3 pb-2">
                    <h1 
                      className="h3 text-start" 
                      onClick={() => on(
                        <>Marketplace con Criptodivisas</>,
                        <>
                          <br/>
                          <h1 className="h3">Plataformas digitales de intercambio organizado en el que se negocian instrumentos financieros, como criptomonedas, productos y servicios.</h1>                        
                        </>,
                        <img className='img-service' src={s12} loading="lazy" title='grafic1' alt= ''/>,                      
                      )}>
                      Marketplace con Criptodivisas                      
                    </h1>
                  </div>
                  <div className="col-12 col-lg-6 pb-lg-3 pb-2">
                    <h1 
                      className="h3 text-start" 
                      onClick={() => on(
                        <>Conectividad Blockchain</>,
                        <>
                          <br/>
                          <h1 className="h3">Enlaza tu proyecto a todos los beneficios y casos de uso que ofrece la blockchain en áreas como la seguridad, el gaming, internet de las cosas, soluciones de pago y regalías.</h1>
                        </>,
                        <img className='img-service' src={s13} loading="lazy" title='grafic1' alt= ''/>,                       
                      )}>
                      Conectividad Blockchain
                    </h1>
                  </div>
                </div>                             
              </div>
            </div>           
          </div>   
        </div>  
    </section>
    </>
  )
}

export default App