import React, {useState} from 'react'
import logo from './../img/logo.svg'
import { GrFormPrevious, GrFormNext } from 'react-icons/gr'

function App() {

  
  const [title, setTitle] = useState(<></>)
  const [description, setDescription] = useState(<></>)

  
  let start = 'animate__fadeInRight'
  let end = 'animate__fadeOutRight'

  function on(title, description) {
    setTitle(title)
    setDescription(description)
    let space = document.getElementsByClassName('spaceinfo')[0]
    space.style.display = 'inline-block'
    space.classList.remove('animate__animated', end, 'animate__faster')
    space.classList.add('animate__animated', start, 'animate__faster')
  }

  function off() {
    let space = document.getElementsByClassName('spaceinfo')[0]
    space.classList.remove('animate__animated', start, 'animate__faster')
    space.classList.add('animate__animated', end, 'animate__faster')
    setTimeout(function() {
      space.style.display = 'none'
    }, 300)
  }


  return (
    <>
    <div className="spaceinfo bg-purple-dark">
      <div onClick={() => off()} className='previous'><GrFormPrevious/></div>      
      <div className="container px-lg-5">
        <div className="row px-lg-5 center-y">
          <div className="col">
            <h3 className="h2 text-center p-5 text-purple mt-3">{title}</h3>
            <div className="h3 text-white p-lg-5 px-5">
              {description}
            </div>
          </div>
        </div>
      </div>
    </div>
    <header>
      <div className="container-fluid px-lg-5" data-aos="zoom-in-down"  data-aos-duration="1000">  
        <div className="row px-5" style={{ display: 'flex', alignItems: 'center'}}>
          <div className="col-12 text-center">
            <img loading="lazy" className="logoheader" src={logo} alt=''/>
          </div>
        </div>                   
        <div className="row py-3" >
          <div className="col-6 px-5 py-1 text-purple text-start">
            <h1 
              className="h3" 
              onClick={() => on(
                <>Misión</>,
                <>
                 Somos una empresa de desarrollo tecnológico y procesos innovadores enfocados en tecnología blockchain, App's, arte y diseño. Nuestro propósito es direccionar nuestro espíritu creativo en pro de la tecnología.
                </>
              )}>
                Misión <GrFormNext className="filter"/>
            </h1>
          </div>
          <div className="col-6 px-5 py-1 text-purple text-end">
            <h1 
              className="h3" 
              onClick={() => on(
                <>Visión</>,
                <>
                 Trabajamos para obtener reconocimiento a nivel regional y nacional en servicios tecnológicos, nuestro mayor interés es convertirnos en un <b>Centro de Innovación y Tecnología </b> a nivel nacional.
                </>
              )}>
                Visión <GrFormNext className="filter"/>
            </h1>
          </div>
        </div>
      </div>
    </header>
    </>
  )
}

export default App